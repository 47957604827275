<template>
  <div class='CommentResponseTemplate'>
    <div class='CommentResponseTemplate-title'>
      {{ $t('unratedAnswer') }}
    </div>
    <div class='CommentResponseTemplate-response'>
      {{ response.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommentResponseTemplates',
  props: {
    response: {
      type: Object,
      default: null
    },
  }
}
</script>

<style lang="scss">
.CommentResponseTemplate {
  display: flex;
  flex-direction: column;

  width: auto;
  height: auto;

  &-title {
    font-style: italic;
    padding-bottom: 1rem;
    color: $light-grey;
  }

  &-response {

  }
}
</style>