<template>
  <div
    class="QuestionsGridCard"
    :class="{ 'QuestionsGridCard--item': question.id }"
  >
    <div class="QuestionsGridCard-title">
      <div v-if="question.id">
        {{ question.text }}
        <span
          v-if="question.mandatory"
          class="QuestionsGridCard-title-mandatory"
          >{{ $t('question.mandatory') }}</span
        >
      </div>
    </div>

    <div
      class="QuestionsGridCard-answer"
      :class="{ 'QuestionsGridCard-answer--real': question.id }"
    >
      <QuestionsGridCardChoice
        v-for="(choice, index) in question.choices"
        :key="index"
        :choice="choice"
        :question-id="question.id"
        :is-checked="choice.value === question.value"
        @handle-input="changeValue"
      />
    </div>
  </div>
</template>
<script>
import QuestionsGridCardChoice from '@/components/questions/QuestionsGridCardAnswer.vue'

export default {
  name: 'QuestionsGridCard',
  components: { QuestionsGridCardChoice },
  props: {
    question: {
      type: Object
    }
  },
  methods: {
    changeValue(newValue) {
      this.$store.dispatch('udpateValueForQuestion', {
        id: this.question.id,
        value: newValue
      })
    }
  }
}
</script>
<style lang="scss">
.QuestionsGridCard {
  width: fit-content;

  border-radius: 10px;
  margin: 10px 0;

  display: flex;

  &--item {
    box-shadow: $light-shadow;
  }

  &-title {
    @media (min-width: $large) {
      width: 25vw;
    }

    width: 200px;

    flex-shrink: 0;
    word-break: break-word;
    margin: 10px 0;
    padding: 0 20px;

    display: flex;
    align-items: center;

    &-mandatory {
      color: red;
    }
  }

  &-answer {
    display: flex;
  }
}
</style>
