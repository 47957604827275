<template>
  <div class="app">
    <main v-if="!isIE" class="app-main">
      <router-view> </router-view>
    </main>
    <PartsIE v-else class="app-isIE" />
  </div>
</template>
<script>
'use strict'

import PartsIE from '@/components/parts/PartsIE.vue'

export default {
  name: 'App',
  components: {
    PartsIE
  },
  data() {
    return {
      isIE: false
    }
  },
  mounted() {
    if (
      navigator.userAgent.indexOf('MSIE') !== -1 ||
      navigator.appVersion.indexOf('Trident/') > -1
    ) {
      this.isIE = true
    }
  }
}
</script>

<style lang="scss">
.app {
  /*changes*/
  height: 100%;

  &-isIE {
    min-height: 100vh;
  }
}
</style>
