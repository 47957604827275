var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"QuestionsBase"},[_c('div',{staticClass:"QuestionsBase-info"},[(!_vm.isQuestionTitle)?_c('div',{staticClass:"QuestionsBase-info-position"},[_vm._v(" "+_vm._s(_vm.position)+" "+_vm._s(' - ')+" ")]):_vm._e(),(_vm.question !== null)?_c('div',{staticClass:"QuestionsBase-info-element"},[_c('div',{staticClass:"QuestionsBase-info-element-title"},[_vm._v(" "+_vm._s(_vm.question.text)+" "),(_vm.question.mandatory)?_c('span',{staticClass:"QuestionsBase-info-element-title-mandatory"},[_vm._v(_vm._s(_vm.$t('question.mandatory')))]):_vm._e()]),(
          _vm.question.help_text !== undefined &&
            _vm.question.help_text !== '' &&
            _vm.question.help_text !== null
        )?_c('div',{staticClass:"QuestionsBase-info-element-helper"},[_vm._v(" "+_vm._s(_vm.question.help_text)+" ")]):_vm._e(),(
          _vm.question.picture !== undefined &&
            _vm.question.picture !== '' &&
            _vm.question.picture !== null
        )?_c('div',[_c('img',{staticClass:"QuestionsBase-info-element-img",attrs:{"src":_vm.question.picture,"alt":"img"}})]):_vm._e()]):_vm._e()]),(!_vm.isQuestionTitle)?_c('div',{staticClass:"QuestionsBase-question"},[_vm._t("default")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }