<template>
  <div class="QuestionResponse">
    <template v-if='fullQuestion.choices.length'>
    <ChoiceResponseTemplate
      v-for="(choice, index) in fullQuestion.choices"
      :key="index"
      :choice="choice"
      :isSelect="isChoiceSelected(choice.value)"
      :showExplication="showExplication"
    />
    </template>
    <template v-else>
      <CommentResponseTemplate :response='response'/>
    </template>

    <button
      v-if="containResponse"
      class="QuestionResponse-button"
      @click="showExplicationAction()"
    >
      {{ buttonText }}
    </button>
  </div>
</template>

<script>
import ChoiceResponseTemplate from '@/components/ChoiceResponseTemplate'
import CommentResponseTemplate from "@/components/CommentResponseTemplate.vue";

export default {
  name: 'QuestionResponse',
  components: {
    ChoiceResponseTemplate,CommentResponseTemplate
  },
  data() {
    return {
      showExplication: false,
      buttonText: this.$t('question.showResponse')
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    question: {
      type: Object,
      default: null
    },
    response: {
      type: Object,
      default: null
    },
    fullQuestion: {
      type: Object,
      default: null
    }
  },
  computed: {
    containResponse() {
      if (
        this.response &&
        this.fullQuestion.choices &&
        this.fullQuestion.choices.length > 0
      ) {
        let isSolutionDetail = false
        this.fullQuestion.choices.forEach(choice => {
          if (choice.solution_detail) {
            isSolutionDetail = true
          }
        })
        return isSolutionDetail
      }
      return false
    }
  },
  methods: {
    isChoiceSelected(value) {
      return (
        this.response.selected_choices.find(c => c.value === value) !==
        undefined
      )
    },
    showExplicationAction() {
      this.showExplication = !this.showExplication
      this.buttonText = this.showExplication
        ? this.$t('question.hideResponse')
        : this.$t('question.showResponse')
    }
  },
  watch: {
    value(changedValue) {
      this.newValue = { value: changedValue }
    }
  },
}
</script>

<style lang="scss">
.QuestionResponse {
  margin-bottom: 1.5rem;

  &-button {
    margin-top: 1rem;
    margin-left: 16px;
    padding: 1rem;

    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    background-color: #ffffff;
    border: 1px solid #dce1e7;
    border-radius: 8px;
  }
}
</style>
