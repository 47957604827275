export default {
  footer: {
    p1:
      'You are receiving this survey because you have enroled a training course with',
    p2: 'qui utilise',
    p3: 'for this surveys.',
    p4: 'Your answers are anonymous.'
  },
  question: {
    mandatory: '*',
    empty: 'None',
    final_mark: 'Final note : ',
    min_mark: 'Minimum score',
    point: '{n} points|{n} point|{n} points',
    max_mark:
      'Questionnaire scored out of {n} points|Questionnaire noté sur {n} point |Questionnaire noté sur {n} points',
    mark_as: 'Questionnaire scored out of',
    response: 'Answer = ',
    finishAlertTitle: 'Thank you for answering !',
    finishAlertText: 'Below are the corrections to the questionnaire.',
    hideResponse: 'Hide explanations',
    showResponse: 'Show explanations',
    confirm: 'Valid',
    notConfirm: 'not Valid',
    strict:
      'This question is in strict mode, all the correct answers must be chosen to validate the question'
  },
  responses: 'Answers',
  unratedAnswer:'Unrated answer :',
  part: {
    alreadyAnswer: 'It seems like you have already answered the survey',
    error: 'An error has occured, please try again later',
    errorTitle: 'Error',
    finishTitle: 'Done',
    finishMsg: 'Thank you for answering',
    footer: {
      sendEnd:
        '|Complete the last required question to send the form|Complete the {n} last required questions to send form',
      sendNext:
        '|Complete the one required question to continue|Complete the required {n} questions to continue',
      previous: 'Previous',
      next: 'Next',
      send: 'Send',
      modal: {
        title: 'Send the survey',
        cancelText: 'Cancel',
        confirmText: 'Yes, send',
        help: 'Are you sure ?'
      },
      error: {
        title: 'Error',
        confirmText: 'Close',
        help: 'An error has occured, please try again later.'
      },
      preview: {
        title: 'Preview',
        confirmText: 'Close',
        help: 'You have reached the end of the preview.'
      }
    },
    header: {
      welcom: 'Welcome'
    },
    ie: {
      title: 'This application does not support Internet Explorer.',
      msg:
        'We recommend using at least Microsoft Edge, Google Chrome or Firefox.'
    },
    notFound: {
      msg: 'Sorry, the survey could not be found.'
    }
  },
  error: {
    surveyAlreadyFilled: 'This survey has already been filled',
    surveyDisabled: 'This survey is disabled',
    surveyExpired: 'This survey has expired'
  }
}
