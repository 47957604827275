<template>
  <QuestionsBase
    v-if="!isGridQuestion"
    :question="formattedQuestion"
    :position="position"
  >
    <component
      :is="typeQuestion"
      :question="formattedQuestion"
      v-model="value"
    />
  </QuestionsBase>
  <QuestionsGrid v-else :questionsGroupe="formattedQuestion.value" />
</template>
<script>
'use strict'

const QuestionsCheckbox = () =>
  import('@/components/questions/QuestionsCheckbox')
const QuestionsChoiceDropdown = () =>
  import('@/components/questions/QuestionsChoiceDropdown')
const QuestionsChoiceMultiple = () =>
  import('@/components/questions/QuestionsChoiceMultiple')
const QuestionsChoiceRange = () =>
  import('@/components/questions/QuestionsChoiceRange')
const QuestionsChoiceUnique = () =>
  import('@/components/questions/QuestionsChoiceUnique')
const QuestionsTextLong = () =>
  import('@/components/questions/QuestionsTextLong')
const QuestionsTextShort = () =>
  import('@/components/questions/QuestionsTextShort')

import QuestionsBase from '@/components/questions/QuestionsBase.vue'
import QuestionsGrid from '@/components/questions/QuestionsGrid.vue'

import { QUESTION } from '@/utils/consts'

export default {
  name: 'QuestionsTemplate',
  components: {
    QuestionsBase,
    QuestionsGrid
  },
  props: {
    question: {
      type: Array,
      required: true
    },
    position: {
      type: Number,
      required: true
    },
    fullQuestion: {
      type: Object,
      default: null
    }
  },
  computed: {
    value: {
      get() {
        return this.formattedQuestion.value
      },
      set(newValue) {
        this.$store.dispatch('udpateValueForQuestion', {
          id: this.formattedQuestion.id,
          value: newValue
        })
      }
    },
    formattedQuestion() {
      if (this.question.length === 1) return this.question[0]
      else
        return {
          type: 'grid',
          value: this.question
        }
    },
    typeQuestion() {
      switch (this.formattedQuestion.type) {
        case QUESTION.TEXT_SHORT:
          return QuestionsTextShort
        case QUESTION.TEXT_LONG:
          return QuestionsTextLong
        case QUESTION.CHOICE_UNIQUE:
          return QuestionsChoiceUnique
        case QUESTION.CHOICE_MULTIPLE:
          return QuestionsChoiceMultiple
        case QUESTION.CHOICE_DROPDOWN:
          return QuestionsChoiceDropdown
        case QUESTION.CHOICE_RANGE:
          return QuestionsChoiceRange
        case QUESTION.CHECKBOX:
          return QuestionsCheckbox
        default:
          return null
      }
    },
    isGridQuestion() {
      return this.formattedQuestion?.type === QUESTION.GRID
    }
  }
}
</script>
