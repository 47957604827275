<template>
  <div class="QuestionsGrid">
    <div class="QuestionsGrid-header">
      <div class="QuestionsGrid-header-position">
        {{ getPosition }} {{ ' - ' }}
      </div>

      <div class="QuestionsGrid-header-title">
        {{ this.questionsGroupe[0].group_name }}
      </div>
    </div>

    <QuestionsGridCard
      v-for="(question, index) in this.formattedQuestionGroupe"
      :key="index"
      :question="question"
    ></QuestionsGridCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import QuestionsTemplate from '@/components/questions/QuestionsTemplate'
import QuestionsGridCard from '@/components/questions/QuestionsGridCard'
import CheckboxSelect from '@/components/element/CheckboxSelect'
import CheckboxSelectImage from '@/components/element/CheckboxSelectImage'

import { comptePosition } from '@/utils/functions'

export default {
  name: 'QuestionsGrid',
  components: {
    QuestionsGridCard,
    QuestionsTemplate,
    CheckboxSelect,
    CheckboxSelectImage
  },
  props: {
    questionsGroupe: {
      type: Array,
      required: true
    }
  },
  computed: {
    ...mapGetters(['reorderQuestionsForPage']),

    formattedQuestionGroupe() {
      let formattedQuestion = this.questionsGroupe[0]

      formattedQuestion = {
        ...formattedQuestion,
        ...{ id: undefined, text: '' }
      }

      return [formattedQuestion, ...this.questionsGroupe]
    },
    getPosition() {
      return comptePosition(
        this.questionsGroupe[0],
        this.reorderQuestionsForPage
      )
    }
  }
}
</script>

<style lang="scss">
.QuestionsGrid {
  box-shadow: $card-shadow;
  border-radius: 10px;

  width: 90%;
  margin: 1rem 5% 0 5%;

  padding: 2rem;

  overflow: scroll;

  &-header {
    display: flex;
    align-items: center;
    text-align: center;

    padding-bottom: 1rem;

    font-size: 1.4rem;

    &-position {
      color: $light-grey;

      margin-right: 5px;
    }

    &-title {
      color: $black-blue;
      font-weight: 600;
    }
  }
}
</style>
