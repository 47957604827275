<template>
  <div class="CheckboxSelect">
    <div
      v-for="tag in tags"
      :key="tag.item"
      class="CheckboxSelect-item"
      :class="[
        classType,
        newValue.some(value => value === tag.value)
          ? 'CheckboxSelect-item-is-selected'
          : ''
      ]"
      @click.stop="changeValue(tag)"
    >
      <BSCCheckbox
        :type="type"
        class="CheckboxSelect-checkbox"
        :value="newValue.some(value => value === tag.value)"
        @click.native.prevent
        @input="changeValue(tag)"
      />
      <span class="CheckboxSelect-span">{{ tag.label }}</span>
    </div>
  </div>
</template>

<script>
'use strict'

export default {
  name: 'CheckboxSelect',
  inheritAttrs: false,

  data() {
    return {
      newValue: this.value
    }
  },
  props: {
    tags: {
      type: Array,
      required: true
    },
    value: {
      value: [String, Number, Boolean, Function, Object, Array, Symbol],
      default: false
    },
    type: {
      type: String,
      default: 'normal'
    },
    isResponse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classType() {
      return [`is-${this.type}`]
    }
  },
  methods: {
    changeValue(tag) {
      if (this.isResponse) return
      if (this.newValue.some(value => value === tag.value))
        this.newValue = this.newValue.filter(value => value !== tag.value)
      else this.newValue = [...this.newValue, tag.value]

      this.$emit('input', this.newValue)
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  }
}
</script>
<style scoped lang="scss">
.CheckboxSelect {
  &-item {
    cursor: pointer;

    display: flex;
    align-items: center;

    min-width: 168px;
    min-height: 46px;

    background-color: $light-grey2;

    margin: 5px 10px;
    border-radius: 5px;
    padding: 12px;

    border: solid 1px transparent;

    &-is-selected {
      border: solid 1px $primary;
      background-color: $white;
    }
  }

  &-checkbox {
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;
  }

  &-span {
    margin-left: 10px;
  }
}
</style>
