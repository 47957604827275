<template>
  <section class="PartsPages">
    <slot />
  </section>
</template>

<script>
'use strict'

export default {
  name: 'PartsPages'
}
</script>

<style lang="scss">
.PartsPages {
  margin: 0 auto;
}
</style>
