import Vue from 'vue'
import VueI18n from 'vue-i18n'

import messages from '@/lang/index.js'

Vue.use(VueI18n)

export default new VueI18n({
  locale: navigator.language.split('-')[0],
  fallbackLocale: 'fr',
  messages: messages
})
