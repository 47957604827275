<template>
  <div class="PartsNotFound">
    <img
      class="PartsNotFound-img"
      src="https://formation.bienvenue.pro/images/Illustrations/eastwood-list-is-empty.png"
      alt="not found"
    />
    <div class="PartsNotFound-error">404</div>
    <div class="PartsNotFound-msg">{{ $t('part.notFound.msg') }}</div>
  </div>
</template>

<script>
'use strict'

export default {
  name: 'PartsNotFound'
}
</script>

<style lang="scss">
.PartsNotFound {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80vh;
  &-img {
    width: 600px;
    height: auto;

    @media (max-width: $remove-logo) {
      display: none;
    }
  }
  &-error {
    padding: 0.75rem;
    font-size: 3rem;
    font-weight: 700;
  }
  &-msg {
    padding: 2rem;
    font-size: 2rem;
    text-align: center;
  }
}
</style>
