<template>
  <div class="PartsFinish">
    <div class="PartsFinish-title">{{ $t('part.finishTitle') }}</div>
    <div class="PartsFinish-msg">{{ $t('part.finishMsg') }}</div>
  </div>
</template>

<script>
'use strict'

export default {
  name: 'PartsFinish'
}
</script>

<style lang="scss">
.PartsFinish {
  &-title {
    padding: 0.75rem;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
  }

  &-msg {
    padding: 2rem;
    font-size: 2rem;
    text-align: center;
  }
}
</style>
