<template>
  <div class="ChoiceResponseTemplate">
    <tr>
      <td>
        <input
          class="ChoiceResponseTemplate-resultCheckbox"
          type="checkbox"
          disabled="disabled"
          :checked="isSelect"
        />
      </td>
      <td>
        <div>
          {{ choice.value }}
          <span
            :class="[
              choice.is_choice_correct
                ? 'ChoiceResponseTemplate-success'
                : 'ChoiceResponseTemplate-fail'
            ]"
          >
            <span class="Point">{{ $tc('question.point', choice.score) }}</span>
            <span
              v-if="
                choice.solution_detail !== '' &&
                  choice.solution_detail !== null &&
                  showExplication
              "
            >
              {{ choice.solution_detail }}
            </span>
          </span>
        </div>
      </td>
    </tr>
  </div>
</template>

<script>
export default {
  name: 'ChoiceResponseTemplate',
  props: {
    choice: {
      type: Object,
      default: null
    },
    isSelect: {
      type: Boolean,
      default: false
    },
    showExplication: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
.ChoiceResponseTemplate {
  width: auto;
  height: auto;

  &-resultCheckbox {
    margin-right: 1rem;
  }
}
</style>
