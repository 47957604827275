<template>
  <div class="QuestionsGridCardChoice">
    <div
      v-if="questionId"
      @click="handleClick"
      class="QuestionsGridCardChoice-input"
    >
      <input type="radio" :checked="isChecked" />
      {{ choice.id }}
    </div>
    <div v-else>
      <div class="QuestionsGridCardChoice-image">
        <img v-if="choice.picture" :src="choice.picture" :alt="choice.value" />
      </div>
      <div class="QuestionsGridCardChoice-text">{{ choice.value }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'QuestionsGridCardChoice',
  emits: ['handle-input'],
  props: {
    choice: {
      type: Object
    },
    isChecked: {
      type: Boolean
    },
    questionId: {
      type: Number
    }
  },
  methods: {
    handleClick() {
      this.$emit('handle-input', this.choice.value)
    }
  }
}
</script>
<style lang="scss">
.QuestionsGridCardChoice {
  @media (min-width: $large) {
    width: 150px;
  }

  width: 80px;

  margin: 0 10px;

  display: flex;
  align-items: flex-end;
  justify-content: center;

  &-input {
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;

    padding: 20px;

    &:hover {
      cursor: pointer;
      background-color: $primary-light;
    }
  }

  &-image {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &-text {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
