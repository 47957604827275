<template>
  <div class="LayoutDefault" id="LayoutDefault">
    <footer class="LayoutDefault-footer">
      <div>
        {{ $t('footer.p1') }}
        <span class="app-footer-org">{{ organization.name }}</span>
        {{ $t('footer.p2') }}
        <span class="app-footer-bienvenue">Bienvenue Formation</span>
        {{ $t('footer.p3') }}
        <span v-if="isAnonyme">{{ $t('footer.p4') }}</span>
      </div>

      <div
        href="https://formation.bienvenue.pro/privacy"
        class="app-footer-rigth"
      >
        <a href="https://formation.bienvenue.pro/privacy"
          >Consultez les Règles de confidentialité</a
        >
      </div>

      <div href="https://bienvenue.pro/formation" class="app-footer-rigth">
        <a href="https://bienvenue.pro/formation">Bienvenue Formation</a>
      </div>

      <div href="https://bienvenue.pro/formation" class="app-footer-rigth">
        <a href="https://bienvenue.pro">bsoft.fr</a> © 2020
      </div>
    </footer>
  </div>
</template>

<script>
'use strict'

import { mapState } from 'vuex'

export default {
  name: 'LayoutDefault',
  computed: {
    ...mapState(['organization', 'isAnonyme'])
  }
}
</script>

<style lang="scss">
.LayoutDefault {
  &-footer {
    display: flex;
    flex-direction: column;
    background-color: $white;
    color: $grey;
    padding: 1rem 0 0;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;

    &-bottom {
      display: flex;
      justify-content: center;
      padding: 0.5rem 0;
    }

    &-columns {
      display: flex;
      justify-content: space-around;
      padding: 0.5rem 0;
      @media (max-width: $x-large) {
        flex-direction: column;
        justify-content: flex-start;
        padding: 0.5rem 1.5rem;
      }
    }

    &-column {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media (max-width: $x-large) {
        padding: 0.5rem 0rem;
      }

      > a {
        color: $white;
      }

      &-pointer {
        cursor: pointer;
      }

      &-lang {
        color: $black;

        > div {
          cursor: pointer;
          padding: 0.5rem;
          width: 150px;

          &:hover {
            background-color: $black;
          }
        }
      }
    }
  }
}

/* Toast Animation */
.toast-item {
  display: inline-block;
  margin-right: 10px;
}

.toast-enter-active,
.toast-leave-active {
  transition: all 1s;
}
.toast-enter, .toast-leave-to /* .toast-leave-active below version 2.1.8 */
 {
  opacity: 0;
  transform: translateY(30px);
}

#launcher {
  z-index: 150 !important;
}
</style>
