<template>
  <div class="PartsFooter">
    <span class="PartsFooter-help" v-if="!requiredPageQuestionsAreFilled">
      <b
        v-if="this.countMandatoryQuestionsNotAnswered"
        class="QuestionsBase-info-element-title-mandatory"
      >
        {{ $t('question.mandatory') }} :
      </b>
      {{
        $tc(
          isLastPage ? 'part.footer.sendEnd' : 'part.footer.sendNext',
          this.countMandatoryQuestionsNotAnswered
        )
      }}
    </span>
    <div class="PartsFooter-wrapper">
      <div>
        <BSCButton
          v-if="nbPage > 1 && !isFirstPage"
          type="secondary"
          @click.native="previous"
        >
          {{ $t('part.footer.previous') }}
        </BSCButton>
      </div>

      <div>
        <BSCButton
          v-if="isConfirmButtonEnabled"
          type="success"
          :disabled="!requiredPageQuestionsAreFilled"
          @click.native="next"
        >
          {{ confirmButtonContent }}
        </BSCButton>
      </div>
    </div>

    <BSCModalAction
      v-if="showDialog"
      class="PartsFooter-send"
      type="success"
      cancelType="secondary"
      confirmType="success"
      :confirmText="$t('part.footer.modal.confirmText')"
      :cancelText="$t('part.footer.modal.cancelText')"
      :loadingConfirm="sendInfo"
      :disabledCancel="sendInfo"
      @confirm="send"
      @cancel="showDialog = false"
    >
      <template #header>
        {{ $t('part.footer.modal.title') }}
      </template>

      <div class="PartsFooter-modal">{{ $t('part.footer.modal.help') }}</div>
    </BSCModalAction>

    <BSCModalAction
      v-if="showError"
      type="danger"
      confirmType="primary"
      :confirmText="$t('part.footer.error.confirmText')"
      @confirm="showError = false"
    >
      <template #header>
        {{ $t('part.footer.error.title') }}
      </template>

      <div class="PartsFooter-modal">{{ $t('part.footer.error.help') }}</div>
    </BSCModalAction>

    <BSCModalAction
      v-if="showPreview"
      type="success"
      confirmType="primary"
      :confirmText="$t('part.footer.preview.confirmText')"
      @confirm="showPreview = false"
    >
      <template #header>
        {{ $t('part.footer.preview.title') }}
      </template>

      <div class="PartsFooter-modal">{{ $t('part.footer.preview.help') }}</div>
    </BSCModalAction>
  </div>
</template>

<script>
'use strict'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PartsFooter',
  data() {
    return {
      showDialog: false,
      showError: false,
      sendInfo: false,
      showPreview: false
    }
  },
  props: {
    canConfirm: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    previous() {
      if (!this.isFirstPage)
        this.$store.dispatch('setPage', this.pagination - 1)
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "instant",
      })
    },
    next() {
      if (!this.isLastPage && this.requiredPageQuestionsAreFilled) {
        this.$store.dispatch('setPage', this.pagination + 1)
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "instant",
        })
      } else if (this.isLastPage && this.requiredPageQuestionsAreFilled)
        this.showDialog = true
    },
    send() {
      if (!this.isPreview) {
        this.sendInfo = true
        this.$store.dispatch('sendSurvey').catch(() => {
          this.sendInfo = false
          this.showDialog = false
          this.showError = true
        })
      } else {
        this.showDialog = false
        this.showPreview = true
      }
    }
  },
  computed: {
    ...mapState(['pagination', 'isPreview']), //A REWORK ! Ne surtout pas utiliser des states directement, il faut passer par des getters !
    ...mapGetters([
      'nbPage',
      'requiredPageQuestionsAreFilled',
      'countMandatoryQuestionsNotAnswered'
    ]),
    isFirstPage() {
      return this.pagination === 1
    },
    isLastPage() {
      return this.pagination === this.nbPage
    },
    isConfirmButtonEnabled() {
      return !this.isLastPage || (this.isLastPage && this.canConfirm)
    },
    confirmButtonContent() {
      return this.isLastPage
        ? this.$t('part.footer.send')
        : this.$t('part.footer.next')
    }
  }
}
</script>

<style lang="scss">
.PartsFooter {
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &-help {
    text-align: center;
  }

  &-modal {
    display: flex;
    padding: 0.75rem 0.2rem;
    font-size: 1.2rem;
  }

  &-wrapper {
    display: flex;
    justify-content: center;
    padding: 1rem 2rem 2rem;

    @media (max-width: $column-button) {
      flex-direction: column;
      align-items: center;
    }

    & > div:first-child {
      margin-right: 10px;
    }
  }
}
</style>
