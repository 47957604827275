<template>
  <div class="CheckboxSelectImage">
    <div
      v-for="tag in tags"
      :key="tag.item"
      class="CheckboxSelectImage-item"
      :class="[
        classType,
        newValue.some(value => value === tag.value) ? 'is-selected' : ''
      ]"
      @click.stop="changeValue(tag)"
    >
      <div>
        <img
          class="CheckboxSelectImage-image"
          v-if="tag.img !== undefined && tag.img !== null"
          :src="tag.img"
          alt="image"
        />
      </div>
      <BSCCheckbox
        :type="type"
        class="CheckboxSelectImage-checkbox"
        :value="newValue.some(value => value === tag.value)"
        @click.native.prevent
        @input="changeValue(tag)"
      />
      <span class="CheckboxSelectImage-checkbox">{{ tag.label }}</span>
    </div>
  </div>
</template>

<script>
'use strict'

export default {
  name: 'CheckboxSelectImage',
  inheritAttrs: false,

  data() {
    return {
      newValue: this.value
    }
  },
  props: {
    tags: {
      type: Array,
      required: true
    },
    value: {
      value: [String, Number, Boolean, Function, Object, Array, Symbol],
      default: false
    },
    type: {
      type: String,
      default: 'normal'
    },
    isResponse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classType() {
      return [`is-${this.type}`]
    }
  },
  methods: {
    changeValue(tag) {
      if (this.isResponse) return
      if (this.newValue.some(value => value === tag.value))
        this.newValue = this.newValue.filter(value => value !== tag.value)
      else this.newValue = [...this.newValue, tag.value]

      this.$emit('input', this.newValue)
    }
  },
  watch: {
    value(value) {
      this.newValue = value
    }
  }
}
</script>
<style lang="scss">
.CheckboxSelectImage {
  display: flex !important;

  &-item {
    max-width: 250px;
  }

  &-image {
    width: 200px;
    height: 200px;
  }

  &-checkbox {
    display: inline;
  }
}
</style>
