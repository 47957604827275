<template>
  <div class="StateResponse">
    <PartsHeader :isResponse="true" />
    <PartsNav v-if="nbPage > 1" />

    <b-alert class="NeedResponse-alert" show variant="success">
      <h4 class="alert-heading">{{ $t('question.finishAlertTitle') }}</h4>
      <p>
        {{ $t('question.finishAlertText') }}
      </p>
    </b-alert>

    <div
      v-if="scale_score || validity_minimum_score || response"
      class="StateResponse-option"
    >
      <span v-if="scale_score">
        {{ $t('question.final_mark') }}
        <span :class="this.isInMin"
          >{{ response.final_score }} / {{ scale_score }}</span
        >
        <span v-if="this.isInMin === 'PointG'">
          {{ $t('question.confirm') }}</span
        >
        <span v-else> {{ $t('question.notConfirm') }}</span></span
      >
    </div>

    <PartsPages>
      <ResponseTemplate
        v-for="question in reorderQuestionsForPage"
        :key="question.id"
        :question="question"
        :fullQuestion="getFullQuestion(question.id)"
        :response="getAnswers(question.id)"
      />
    </PartsPages>
    <PartsFooter :canConfirm="false" />
  </div>
</template>

<script>
import PartsHeader from '@/components/parts/PartsHeader'
import { mapGetters } from 'vuex'
import PartsNav from '@/components/parts/PartsNav'
import PartsPages from '@/components/parts/PartsPages'
import ResponseTemplate from '@/components/questions/ResponseTemplate'
import PartsFooter from '@/components/parts/PartsFooter'

export default {
  name: 'StateResponse',
  components: {
    PartsHeader,
    PartsNav,
    PartsPages,
    ResponseTemplate,
    PartsFooter
  },

  computed: {
    ...mapGetters([
      'nbPage',
      'reorderQuestionsForPage',
      'response',
      'scale_score',
      'validity_minimum_score'
    ]),
    isInMin() {
      if (this.response.final_score < this.validity_minimum_score) {
        return 'PointR'
      } else {
        return 'PointG'
      }
    }
  },
  methods: {
    getAnswers(questionId) {
      return this.response.answers.find(
        answer => answer.question_id === questionId
      )
    },
    getFullQuestion(questionId) {
      return this.response.survey.questions.find(
        question => question.id === questionId
      )
    }
  }
}
</script>

<style lang="scss">
.StateResponse {
  &-option {
    height: auto;
    align-content: end;
    align-items: end;
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 0.9rem;
    margin-top: 0.9rem;
  }
}
</style>
