<template>
  <header class="PartsHeader">
    <img :src="surveyLogo" class="PartsHeader-logo" />
    <div class="PartsHeader-info">
      <h1 class="PartsHeader-info-title">{{ getSurveyName }}</h1>
      <div v-if="!getIsPreview && getUser" class="PartsHeader-info-account">
        {{ getUser.firstname }} {{ getUser.lastname }}
      </div>
    </div>
  </header>
</template>

<script>
'use strict'

import { mapGetters } from 'vuex'

export default {
  name: 'PartsHeader',
  props: {
    isResponse: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters([
      'getUser',
      'getSurveyName',
      'getIsPreview',
      'getOrganisation'
    ]),
    surveyLogo() {
      const defaultLogo = '/Bienvenue.svg'
      if (!this.getOrganisation) return defaultLogo
      return this.getOrganisation.settings.logo_url !== null
        ? this.getOrganisation.settings.logo_url
        : defaultLogo
    },
    isTypeEvaluation() {
      let url = new URL(location.href)
      let searchParams = new URLSearchParams(url.search)
      let type = searchParams.get('type')
      return type === 'evaluation'
    }
  }
}
</script>

<style lang="scss">
.PartsHeader {
  display: flex;
  background-color: $black-blue;
  color: $white;
  box-sizing: border-box;
  border: 2px solid #8c8c8c;
  flex-wrap: wrap;

  @media (max-width: $remove-logo) {
    height: initial;
  }

  &-logo {
    align-content: start;
    margin-left: 1%;
    margin-bottom: 0.9rem;
    margin-top: 0.9rem;
    justify-content: start;
    width: 5%;
    height: 100%;
    vertical-align: center;

    @media (max-width: $break-question) {
      margin-left: initial;
    }
    @media (max-width: $remove-logo) {
      display: none;
    }
  }

  &-info {
    align-content: center;
    justify-content: center;
    width: 94%;
    text-align: center;
    padding-top: 10px;

    &-title {
      font-weight: 600;
      font-size: 1.9rem;

      @media (max-width: $remove-logo) {
        font-size: 1.2rem;
      }
    }

    &-account {
      font-weight: 500;
      font-size: 1.2rem;
      margin-top: 1.5rem;
      align-content: end;
      @media (max-width: $remove-logo) {
        display: none;
      }
    }

    &-responses {
      text-align: center;
      font-size: 1.9rem;
    }
  }

  &-option {
    height: auto;
    align-content: end;
    align-items: end;
    margin-left: 5%;
    text-align: center;
    width: 100%;
    font-size: 1.1rem;
    margin-bottom: 0.9rem;

    &-point {
      color: #fff;
      background-color: #007bff;
      margin-left: 0.5em;
      align-content: center;
      display: inline-block;
      padding: 0.25em 0.4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    &-pointR {
      color: #fff;
      background-color: #ff0000;
      margin-left: 0.5em;
      align-content: center;
      display: inline-block;
      padding: 0.25em 0.4em;
      font-size: 75%;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      white-space: nowrap;
      vertical-align: baseline;
      border-radius: 0.25rem;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}
</style>
