import axios from 'axios'

const survey = token => {
  return axios
    .get(`${process.env.VUE_APP_API}/api/survey-api/entries/${token}`)
    .then(({ data }) => {
      return Promise.resolve(data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

const send = (token, answers) => {
  return axios
    .post(
      `${process.env.VUE_APP_API}/api/survey-api/entries/${token}/submit`,
      answers
    )
    .then(({ data }) => {
      return Promise.resolve(data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

const preview = token => {
  return axios
    .get(
      `${process.env.VUE_APP_API}/api/survey-api/preview?preview_token=${token}`
    )
    .then(({ data }) => {
      return Promise.resolve(data)
    })
    .catch(error => {
      return Promise.reject(error)
    })
}

export { survey, send, preview }
