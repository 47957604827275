import { QUESTION } from '@/utils/consts'

export const comptePosition = (currentQuestion, reorderQuestionsForPage) => {
  return (
    currentQuestion.order_in_page -
    reorderQuestionsForPage.filter(
      fQuestion =>
        fQuestion.type === QUESTION.TITLE &&
        fQuestion.order_in_page <= currentQuestion.order_in_page
    ).length +
    1
  )
}
