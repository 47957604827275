<template>
  <nav class="PartsNav">
    <BSCSteppers :steps="steps" type="success" :value="pagination - 1" />
  </nav>
</template>

<script>
'use strict'

import { mapState, mapGetters } from 'vuex'

export default {
  name: 'PartsNav',
  computed: {
    ...mapState(['pagination']),
    ...mapGetters(['nbPage']),
    steps() {
      let steps = []
      for (let i = 1; i <= this.nbPage; i++) {
        steps = [...steps, i]
      }
      return steps
    }
  }
}
</script>

<style lang="scss">
.PartsNav {
  display: flex;
  justify-content: center;
  background-color: $grey-ligth;

  overflow: auto;
  padding: 1rem;
  line-height: 1;
}
</style>
