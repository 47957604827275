export const QUESTION = {
  TITLE: 'title',
  COMMENT: 'comment',
  TEXT_SHORT: 'text_short',
  TEXT_LONG: 'text_long',
  CHOICE_UNIQUE: 'choice_unique',
  CHOICE_MULTIPLE: 'choice_multiple',
  CHOICE_DROPDOWN: 'choice_dropdown',
  CHOICE_RANGE: 'choice_range',
  CHECKBOX: 'checkbox',
  GRID: 'grid'
}

export const STATE = {
  LOADING: 'loading',
  NEED_RESPONSE: 'need_response',
  FINISH: 'finish',
  NOT_FOUND: 'not_found',
  ALREADY_DONE: 'already_done',
  ERROR: 'error',
  RESPONSE: 'response'
}
