import Vue from 'vue'
import App from './App.vue'
import store from './store'
import VueRouter from 'vue-router'

// Bugsnag
import bugsnagClient from './bugsnag'
import bugsnagVue from '@bugsnag/plugin-vue'

bugsnagClient.use(bugsnagVue, Vue)

import bienvenue_components from '../node_modules/bienvenue_components/'

Object.values(bienvenue_components).forEach(component => {
  Vue.component(component.name, component)
})

import '@/assets/style/app.scss'

import i18n from './i18n'

Vue.use(VueRouter)

import Routes from './router/index.js'

const router = new VueRouter({
  routes: Routes
})

new Vue({
  store,
  i18n,
  render: h => h(App),
  router: router
}).$mount('#app')

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

// This imports all the layout components such as <b-container>, <b-row>, <b-col>:
import { LayoutPlugin } from 'bootstrap-vue'
Vue.use(LayoutPlugin)

// This imports <b-modal> as well as the v-b-modal directive as a plugin:
import { ModalPlugin } from 'bootstrap-vue'
Vue.use(ModalPlugin)

// This imports <b-card> along with all the <b-card-*> sub-components as a plugin:
import { CardPlugin } from 'bootstrap-vue'
Vue.use(CardPlugin)

// This imports directive v-b-scrollspy as a plugin:
import { VBScrollspyPlugin } from 'bootstrap-vue'
Vue.use(VBScrollspyPlugin)

// This imports the dropdown and table plugins
import { DropdownPlugin, TablePlugin } from 'bootstrap-vue'
Vue.use(DropdownPlugin)
Vue.use(TablePlugin)
