<template>
  <div class="questionnaire">
    <div class="questionnaire-main">
      <DataLoading v-if="state === STATE.LOADING" />
      <NeedResponse v-if="state === STATE.NEED_RESPONSE" />
      <div v-else-if="state === STATE.FINISH">
        <PartsHeader />
        <PartsFinish />
      </div>
      <PartsNotFound v-else-if="state === STATE.NOT_FOUND" />
      <PartsAlreadyDone v-else-if="state === STATE.ALREADY_DONE" />
      <PartsError v-else-if="state === STATE.ERROR" />
      <StateResponse v-else-if="state === STATE.RESPONSE" />
    </div>
    <LayoutDefault />
  </div>
</template>

<script>
'use strict'

import { mapState } from 'vuex'

import PartsHeader from '@/components/parts/PartsHeader.vue'
import PartsNotFound from '@/components/parts/PartsNotFound.vue'
import PartsAlreadyDone from '@/components/parts/PartsAlreadyDone.vue'
import PartsError from '@/components/parts/PartsError.vue'
import PartsFinish from '@/components/parts/PartsFinish.vue'
import DataLoading from '@/components/parts/DataLoading'

import { STATE } from '@/utils/consts'
import LayoutDefault from '@/components/LayoutDefault'
import NeedResponse from '@/components/parts/NeedResponse'
import StateResponse from '@/components/parts/StateResponse'

export default {
  name: 'Questionnaire',
  components: {
    PartsHeader,
    DataLoading,
    PartsNotFound,
    PartsAlreadyDone,
    PartsError,
    PartsFinish,
    LayoutDefault,
    NeedResponse,
    StateResponse
  },
  data() {
    return {
      STATE: STATE
    }
  },
  computed: {
    ...mapState(['state'])
  }
}
</script>

<style lang="scss">
.PointR {
  color: #fff;
  background-color: #ff0000;
  margin-left: 0.5em;
  align-content: center;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Point {
  color: #000000;
  background-color: #fff;
  border: 1px solid #000000;
  margin-left: 0.5em;
  align-content: center;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PointB {
  color: #fff;
  background-color: #007bff;
  margin-left: 0.5em;
  align-content: center;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.PointG {
  color: #fff;
  background-color: #13ae4b;
  margin-left: 0.5em;
  align-content: center;
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.questionnaire {
  position: relative;
  overflow: hidden;

  &-main {
    min-height: 100vh;
    overflow: hidden;
    border: #000 1px solid;
  }

  &-footer {
    height: auto;
    bottom: 0;
    width: 100%;
    padding: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $grey;
    background: $white;
    box-shadow: 0px 0 4px 3px $grey-ligth;

    > div {
      text-align: center;
    }

    &-rigth {
      padding-top: 0.5rem;

      > a {
        color: $primary;
      }
    }
  }
}
</style>
