<template>
  <QuestionsBase
    :question="question"
    :fullQuestion="fullQuestion"
    :response="response"
  >
    <div class="ResponseTemplate-info" v-if="fullQuestion.strict_mode">
      {{ $t('question.strict') }}
    </div>

    <QuestionResponse
      v-if="response !== null"
      :question="question"
      :fullQuestion="fullQuestion"
      :response="response"
      v-model="value"
    />
  </QuestionsBase>
</template>

<script>
import QuestionsBase from '@/components/questions/QuestionsBase'
import QuestionResponse from '@/components/questions/QuestionResponse'

export default {
  name: 'ResponseTemplate',
  components: {
    QuestionsBase,
    QuestionResponse
  },
  props: {
    question: {
      type: Object,
      required: true
    },
    fullQuestion: {
      type: Object,
      default: null
    },
    response: {
      type: Object,
      default: null
    }
  },
  computed: {
    value: {
      get() {
        return this.question.value
      },
      set(newValue) {
        this.$store.dispatch('udpateValueForQuestion', {
          id: this.question.id,
          value: newValue
        })
      }
    }
  }
}
</script>

<style lang="scss"></style>
