<template>
  <div class="NeedResponse">
    <PartsHeader />

    <PartsNav v-if="nbPage > 1" />

    <div
      v-if="scale_score || validity_minimum_score"
      class="NeedResponse-option"
    >
      <span v-if="scale_score">
        {{ $t('question.mark_as') }} {{ $tc('question.point', scale_score) }}
      </span>
      <br />
      <span v-if="validity_minimum_score">
        {{ $t('question.min_mark') }}
        {{ $tc('question.point', validity_minimum_score) }}
      </span>
    </div>

    <PartsPages>
      <QuestionsTemplate
        v-for="(question, index) in reorderQuestionsGroupe"
        :key="index"
        :question="question"
        :position="getPosition(question)"
      />
    </PartsPages>
    <PartsFooter />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import PartsHeader from '@/components/parts/PartsHeader'
import PartsNav from '@/components/parts/PartsNav'
import PartsPages from '@/components/parts/PartsPages'
import QuestionsTemplate from '@/components/questions/QuestionsTemplate'
import PartsFooter from '@/components/parts/PartsFooter'

import { comptePosition } from '@/utils/functions'

export default {
  name: 'NeedResponse',
  components: {
    PartsHeader,
    PartsNav,
    PartsPages,
    PartsFooter,
    QuestionsTemplate
  },
  computed: {
    ...mapGetters([
      'nbPage',
      'reorderQuestionsForPage',
      'reorderQuestionsGroupe',
      'scale_score',
      'validity_minimum_score'
    ])
  },
  methods: {
    getPosition(question) {
      return comptePosition(question[0], this.reorderQuestionsForPage)
    }
  }
}
</script>

<style lang="scss">
.NeedResponse {
  &-alert {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &-option {
    height: auto;
    align-content: end;
    align-items: end;
    text-align: center;
    width: 100%;
    font-size: 1.5rem;
    margin-bottom: 0.9rem;
    margin-top: 0.9rem;
  }
}
</style>
