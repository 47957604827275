<template>
  <div class="PartsLoading" v-if="loading">
    <div class="PartsLoading-wrapper">
      <div class="PartsLoading-circle" />
      <p class="PartsLoading-text">{{ label }}</p>
    </div>
  </div>
</template>

<script>
'use strict'

import { STATE } from '@/utils/consts'

export default {
  name: 'DataLoading',
  data() {
    return {
      STATE: STATE
    }
  },
  props: {
    label: {
      type: String,
      default: 'Chargement'
    },
    loading: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    let url = new URL(location.href)
    let searchParams = new URLSearchParams(url.search)
    let token = searchParams.get('q')

    this.$store
      .dispatch('loadData', {
        isPreview: location.href.includes('preview'),
        token: token
      })
      .then(() => {
        this.$store.dispatch('setState', STATE.NEED_RESPONSE)
      })
  }
}
</script>

<style lang="scss">
.PartsLoading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 300;
  flex-direction: column;
  user-select: none;
  background-color: $grey-ligth;
  opacity: 0.8;

  &-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }

  &-circle {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    border: 2px solid transparent;
    border-left-color: #ababab;
    animation: circleanimation 0.45s linear infinite;
  }

  &-text {
    margin-left: -10px;
    margin-top: 15px;
    color: #808080;
    font-size: 12px;
  }
}

@keyframes circleanimation {
  from {
    transform: rotateZ(0deg);
  }
  to {
    transform: rotateZ(360deg);
  }
}
</style>
