var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ChoiceResponseTemplate"},[_c('tr',[_c('td',[_c('input',{staticClass:"ChoiceResponseTemplate-resultCheckbox",attrs:{"type":"checkbox","disabled":"disabled"},domProps:{"checked":_vm.isSelect}})]),_c('td',[_c('div',[_vm._v(" "+_vm._s(_vm.choice.value)+" "),_c('span',{class:[
            _vm.choice.is_choice_correct
              ? 'ChoiceResponseTemplate-success'
              : 'ChoiceResponseTemplate-fail'
          ]},[_c('span',{staticClass:"Point"},[_vm._v(_vm._s(_vm.$tc('question.point', _vm.choice.score)))]),(
              _vm.choice.solution_detail !== '' &&
                _vm.choice.solution_detail !== null &&
                _vm.showExplication
            )?_c('span',[_vm._v(" "+_vm._s(_vm.choice.solution_detail)+" ")]):_vm._e()])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }