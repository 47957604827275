<template>
  <div class="PartsError">
    <img
      class="PartsError-img"
      src="https://formation.bienvenue.pro/images/Illustrations/eastwood-fatal-error.png"
      alt="not found"
    />
    <div class="PartsError-error">{{ $t('part.errorTitle') }}</div>
    <div class="PartsError-msg" v-if="errorMessage">{{ errorMessage }}</div>
    <div class="PartsError-msg" v-else>{{ $t('part.error') }}</div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

;('use strict ')

export default {
  name: 'PartsError',
  computed: {
    ...mapState(['errorMessage'])
  }
}
</script>

<style lang="scss">
.PartsError {
  &-img {
    width: 600px;
    height: auto;
    display: block;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: $remove-logo) {
      display: none;
    }
  }

  &-error {
    padding: 0.75rem;
    font-size: 3rem;
    font-weight: 700;
    text-align: center;
  }

  &-msg {
    padding: 2rem;
    font-size: 2rem;
    text-align: center;
  }
}
</style>
